import styled from 'styled-components';
import Image from 'next/image';
import { Card, Typography, Button } from 'antd';
import { timingFunctions } from 'polished';

import { PageContainer } from 'styles/components';

import { fadeInUp } from 'containers/Auth/Register/styles';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile: boolean;
}

export const Container = styled(PageContainer)<CommonProps>`
  &&& {
    position: relative;
    background-color: ${theme.colors.lavenderGrey};
    padding: ${({ $isMobile }) => ($isMobile ? '0 30px' : '0 60px')};
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
`;

export const StyledCard = styled(Card)<CommonProps>`
  &&& {
    max-width: 800px;
    margin: 30px auto 0;
    box-shadow: ${theme.shadows.card};
    padding: ${({ $isMobile }) =>
      $isMobile ? '20px 20px 40px' : '60px 80px 70px 60px'};

    animation: ${fadeInUp} 1s ${timingFunctions('easeOutQuart')};
    opacity: 0;
    animation-fill-mode: forwards;

    .ant-card-body {
      padding: 0;
    }
  }
`;

export const Character = styled(Image)<CommonProps>`
  width: ${({ $isMobile }) => ($isMobile ? '240px' : '300px')};
  height: auto;
`;

export const ContentWrapper = styled.div<CommonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const StyledTitle = styled(Typography.Title)<CommonProps>`
  &&& {
    margin-bottom: 0;
  }
`;

export const StyledText = styled(Typography.Text)`
  &&& {
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  &&& {
    min-width: 150px;
  }
`;
