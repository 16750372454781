import Head from 'next/head';
import Link from 'next/link';
import { Grid, Flex } from 'antd';

import {
  Container,
  BackgroundImage,
  StyledCard,
  Character,
  ContentWrapper,
  StyledTitle,
  StyledText,
  StyledButton,
} from './styles';

import backgroundImage from 'assets/images/register/background.jpg';
import lighthouseImage from 'assets/images/register/lighthouse.webp';

function FourZeroFour() {
  const screens = Grid.useBreakpoint();

  const isMobile = screens.md === false;

  return (
    <>
      <Head>
        <title key="title">Shuttle</title>
        <meta
          property="og:url"
          content={`${process.env.NEXTAUTH_URL}/auth/confirm-email`}
          key="og:url"
        />
      </Head>
      <Container $isMobile={isMobile}>
        <BackgroundImage
          src={backgroundImage}
          alt="Background Image"
          priority
        />
        <StyledCard $isMobile={isMobile}>
          <Flex
            justify="space-around"
            align="center"
            gap={isMobile ? 0 : 40}
            vertical={isMobile}
          >
            <Character
              src={lighthouseImage}
              alt=""
              priority
              $isMobile={isMobile}
            />
            <ContentWrapper $isMobile={isMobile}>
              <StyledTitle $isMobile={isMobile}>404</StyledTitle>
              <StyledText type="secondary">
                Whoops! The page you&apos;re looking for doesn&apos;t seem to
                exist. Please head back to our homepage to find what you need.
              </StyledText>
              <Link href="/">
                <StyledButton type="primary">Home</StyledButton>
              </Link>
            </ContentWrapper>
          </Flex>
        </StyledCard>
      </Container>
    </>
  );
}

export default FourZeroFour;
