import styled, { keyframes } from 'styled-components';
import Image from 'next/image';
import { Card, Typography, Progress } from 'antd';
import { timingFunctions } from 'polished';

import { PageContainer } from 'styles/components';

import theme from 'styles/theme';

interface CommonProps {
  $isMobile: boolean;
}

// Define the animation keyframes
export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled(PageContainer)<CommonProps>`
  &&& {
    position: relative;
    background-color: ${theme.colors.lavenderGrey};
    padding: ${({ $isMobile }) => ($isMobile ? '0 30px' : '0 60px')};
    height: ${({ $isMobile }) => ($isMobile ? '95vh' : '100vh')};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  transition: opacity 1s ${timingFunctions('easeOutQuart')};
`;

export const StyledCard = styled(Card)<CommonProps>`
  &&& {
    width: ${({ $isMobile }) => ($isMobile ? '100%' : '550px')};
    margin: 0 auto;
    box-shadow: ${theme.shadows.card};
    padding: ${({ $isMobile }) => ($isMobile ? '10px 0px' : '20px 60px 40px')};

    animation: ${fadeInUp} 1s ${timingFunctions('easeOutQuart')};
    opacity: 0;
    animation-fill-mode: forwards;
  }
`;

export const Title = styled(Typography.Title)<CommonProps>`
  &&& {
    margin: ${({ $isMobile }) => ($isMobile ? '0 0 30px' : '20px 0 40px')};
    text-align: center;
  }
`;

export const StyledProgress = styled(Progress)`
  &&& {
    width: calc(100% - 40px);
    margin-left: 20px;
    display: block;
  }
`;

export const SignInText = styled(Typography.Text)`
  &&& {
    text-align: center;
    display: block;
    margin: 5px 0 40px;
  }
`;

export const RecaptchaText = styled(Typography.Text)`
  &&& {
    font-size: 12px;
    text-align: center;
    display: block;
    color: #b5b9c9;
  }
`;

export const StyledLink = styled.a`
  text-decoration: underline;
`;
